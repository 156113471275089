import React from 'react';
import { Divider, Flex, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useTranslation } from 'localization';
import { Transaction } from 'types';

import Button from '../‌Button';

type XmAdditionalDataProps = {
  transaction: Transaction;
};

const XmAdditionalData: React.FC<XmAdditionalDataProps> = ({
  transaction,
}: XmAdditionalDataProps) => {
  const { t } = useTranslation(
    'component.dashboard.recentTransactions.modal.additionalData'
  );
  const [show, setShow] = React.useState<boolean>(false);

  const depositBrokerDetail = [
    { title: 'id', value: transaction.id },
    { title: 'status', value: transaction.status },
    {
      title: 'created_at',
      value: dayjs(transaction.created_at).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: 'updated_at',
      value: dayjs(transaction.updated_at).format('YYYY-MM-DD HH:mm:ss'),
    },
    { title: 'amount', value: transaction.amount },
    { title: 'destination_amount', value: transaction.destination_amount },
    { title: 'fee_amount', value: transaction.fee.fee_amount },
    { title: 'currency', value: transaction.form.deposit_broker?.currency },
    {
      title: 'trading_account',
      value: transaction.form.deposit_broker?.trading_account,
    },
    {
      title: 'payment_id',
      value: transaction.form.deposit_broker?.payment_id,
    },
    {
      title: 'payer_name',
      value: transaction.form.deposit_broker?.payer_name,
    },
    {
      title: 'client_name',
      value: transaction.form.deposit_broker?.client_name,
    },
    {
      title: 'client_email',
      value: transaction.form.deposit_broker?.client_email,
    },
    {
      title: 'suggested_memo',
      value: transaction.form.deposit_broker?.suggested_memo,
    },
    { title: 'magix_id', value: transaction.form.deposit_broker?.magix_id },
  ];
  const withdrawBrokerDetail = [
    { title: 'id', value: transaction.id },
    { title: 'status', value: transaction.status },
    {
      title: 'created_at',
      value: dayjs(transaction.created_at).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: 'updated_at',
      value: dayjs(transaction.updated_at).format('YYYY-MM-DD HH:mm:ss'),
    },
    { title: 'amount', value: transaction.amount },
    { title: 'destination_amount', value: transaction.destination_amount },
    { title: 'fee_amount', value: transaction.fee.fee_amount },
    { title: 'currency', value: transaction.form.withdraw_broker?.currency },
    {
      title: 'payee_account',
      value: transaction.form.withdraw_broker?.payee_account,
    },
    {
      title: 'trading_account',
      value: transaction.form.withdraw_broker?.trading_account,
    },
    {
      title: 'payment_id',
      value: transaction.form.withdraw_broker?.payment_id,
    },
    {
      title: 'payee_name',
      value: transaction.form.withdraw_broker?.payee_name,
    },
    {
      title: 'client_name',
      value: transaction.form.withdraw_broker?.client_name,
    },
    {
      title: 'client_email',
      value: transaction.form.withdraw_broker?.client_email,
    },
    {
      title: 'suggested_memo',
      value: transaction.form.withdraw_broker?.suggested_memo,
    },
    { title: 'magix_id', value: transaction.form.withdraw_broker?.magix_id },
  ];

  return (
    <Flex w="100%" flexDir="column" align="center" justify="center" mb={4}>
      <Divider mb={4} />
      {show ? (
        <Flex flexDir="column" gap={4} w="100%">
          {transaction.transaction_method.key === 'DEPOSIT_BROKER:XM' &&
            depositBrokerDetail.map((item) => (
              <Flex key={item.title} align="center" justify="space-between">
                <Text color="body2">{t(item.title)}</Text>
                <Text fontSize="md" color="body">
                  {item.value}
                </Text>
              </Flex>
            ))}
          {transaction.transaction_method.key === 'WITHDRAW_BROKER:XM' &&
            withdrawBrokerDetail.map((item) => (
              <Flex key={item.title} align="center" justify="space-between">
                <Text color="body2">{t(item.title)}</Text>
                <Text fontSize="md" color="body">
                  {item.value}
                </Text>
              </Flex>
            ))}
        </Flex>
      ) : (
        <Button
          variant="filled"
          size="sm"
          onClick={() => setShow(true)}
          title={t('moreData')}
        />
      )}
    </Flex>
  );
};
export default XmAdditionalData;
