import React from 'react';
import { Accordion, AccordionItem, Stack, Text } from '@chakra-ui/react';
import { useTranslation } from 'localization';
import { Proposal } from 'types';

import { LDProposalAccordionButton, Spinner } from 'components';

import ProposalCard from '../ProposalCard';

type ProposalsProps = {
  proposals: Proposal[];
  loading: boolean;
  refetch: () => void;
};

const Proposals: React.FC<ProposalsProps> = ({
  proposals,
  loading,
  refetch,
}) => {
  const { t } = useTranslation('component.requests.proposals');

  const [active, setActive] = React.useState<null | Proposal>(null);
  return (
    <Stack w={{ base: '100%' }}>
      <Text fontSize="xl" color="primary">
        {t('inProcessRequests')}
      </Text>
      {loading && (
        <Stack w="100%" py={4} justify="center" align="center">
          <Spinner />
        </Stack>
      )}
      {proposals.length === 0 && (
        <Stack w="100%" py={4} justify="center" align="center">
          <Text>{t('noProposal')}</Text>
        </Stack>
      )}
      <Accordion
        allowToggle
        w="100%"
        onChange={(active) => {
          if (proposals && active !== null) {
            setActive(
              proposals.filter((proposal) => proposal.status !== 'COMPLETED')[
                active as number
              ]
            );
          }
        }}
      >
        {proposals
          .filter((proposal) => proposal.status !== 'COMPLETED')
          .map((proposal) => {
            return (
              <Stack key={proposal.id} w="100%">
                <AccordionItem
                  bgColor={active?.id === proposal.id ? 'lightPrimary' : ''}
                >
                  <LDProposalAccordionButton
                    createdAt={proposal.created_at}
                    username={proposal?.source_wallet?.user?.username}
                    status={proposal.status}
                    panel="LD"
                    transaction_type="WITHDRAW"
                  />
                  <ProposalCard proposal={proposal} refetch={refetch} />
                </AccordionItem>
              </Stack>
            );
          })}
      </Accordion>
    </Stack>
  );
};

export default Proposals;
