import { AccordionPanel, Stack } from '@chakra-ui/react';
import { useTranslation } from 'localization';
import { Transaction } from 'types';

import {
  CopyClipBoard,
  DownloadCard,
  LDTransactionCard,
  LDTransactionKeyValue,
  LDTransactionMessage,
} from 'components';

import CompleteLdDeposit from '../CompleteLdDeposit';

type TransactionCardProps = {
  transaction: Transaction;
  refetch: () => void;
};

const TransactionCard: React.FC<TransactionCardProps> = ({
  transaction,
  refetch,
}: TransactionCardProps) => {
  const { t } = useTranslation('component.ldRequests.transactions');

  const STATES: Record<Transaction['status'], React.ReactElement | string> = {
    INIT: '',
    AUTHORIZED: '',
    APPROVED: '',
    UNAUTHORIZED: (
      <Stack>
        <LDTransactionMessage
          title={t('transactionNotCleared')}
          status="error"
          textProps={{ color: 'error' }}
        />
      </Stack>
    ),
    CLEARED: transaction?.form.deposit_ld.proposal ? (
      transaction?.form.deposit_ld.proposal.payment_method === 'CASH' ? (
        <Stack flexDir="column">
          <LDTransactionKeyValue
            title={t('address')}
            value={transaction?.form.deposit_ld.proposal.address || '-'}
          />
          {transaction?.source_wallet?.user?.ld_contract?.description && (
            <LDTransactionMessage
              title={transaction?.source_wallet?.user?.ld_contract?.description}
              status="warning"
              textProps={{ color: 'warning' }}
            />
          )}
          <LDTransactionMessage
            title={t('addressHint')}
            status="info"
            textProps={{ color: 'info' }}
          />
        </Stack>
      ) : transaction?.form.deposit_ld?.bank_receipt ? (
        <Stack flexDir="column">
          <LDTransactionMessage
            title={t('waitForLDApprove')}
            status="info"
            textProps={{ color: 'info' }}
          />
        </Stack>
      ) : (
        <Stack>
          <LDTransactionKeyValue
            title={t('bankName')}
            value={
              transaction?.form.deposit_ld?.proposal.ld_bank_account
                ?.bank_name || ''
            }
          />
          <LDTransactionKeyValue
            title={t('holderName')}
            value={
              transaction?.form.deposit_ld?.proposal.ld_bank_account
                ?.holder_name || ''
            }
            flexDir={{ base: 'column', md: 'row' }}
          />
          <LDTransactionKeyValue
            title={t('number')}
            value={
              <CopyClipBoard
                initialValue={
                  transaction?.form.deposit_ld?.proposal.ld_bank_account
                    ?.account_id || ''
                }
              />
            }
            flexDir={{ base: 'column', md: 'row' }}
          />
          {transaction?.form.deposit_ld?.proposal.ld_bank_account?.IBAN && (
            <LDTransactionKeyValue
              title={t('iban')}
              value={
                <CopyClipBoard
                  initialValue={
                    transaction?.form.deposit_ld?.proposal.ld_bank_account
                      ?.IBAN || ''
                  }
                />
              }
              flexDir={{ base: 'column', md: 'row' }}
            />
          )}
          {transaction?.form.deposit_ld?.proposal.ld_bank_account
            ?.description && (
            <LDTransactionKeyValue
              title={t('description')}
              value={
                transaction?.form.deposit_ld?.proposal.ld_bank_account
                  ?.description || ''
              }
              flexDir="column"
            />
          )}
          <CompleteLdDeposit
            transactionId={transaction?.id || ''}
            refetch={refetch}
          />
        </Stack>
      )
    ) : (
      <Stack flexDir="column">
        <LDTransactionMessage
          title="Cleared"
          status="info"
          textProps={{ color: 'info' }}
        />
      </Stack>
    ),
    NOT_CLEARED: (
      <Stack>
        <LDTransactionMessage
          title={t('transactionNotCleared')}
          status="error"
          textProps={{ color: 'error' }}
        />
      </Stack>
    ),
    CANCELED: (
      <Stack>
        <LDTransactionMessage
          title={t('transactionNotCleared')}
          status="error"
          textProps={{ color: 'error' }}
        />
      </Stack>
    ),
    SETTLED: (
      <Stack>
        {transaction?.form.deposit_ld.proposal
          ? transaction?.form.deposit_ld.proposal.payment_method ===
              'BANK_TRANSFER' && (
              <DownloadCard
                file={transaction?.form.deposit_ld?.bank_receipt || ''}
              />
            )
          : ''}
        <LDTransactionMessage
          title={t('transactionDone')}
          status="success"
          textProps={{ color: 'success' }}
        />
      </Stack>
    ),
    DENIED: (
      <Stack>
        <LDTransactionMessage
          title={t('transactionNotCleared')}
          status="error"
          textProps={{ color: 'error' }}
        />
      </Stack>
    ),
    FAILED: '',
    SETTLING: '',
  };

  return (
    <AccordionPanel pb={4}>
      <LDTransactionCard
        amount={transaction.amount}
        identifier_number={transaction?.source_wallet?.user?.identifier_number}
        pay_with={transaction.form.deposit_ld?.proposal?.pay_with}
        symbol={transaction?.destination_wallet?.currency?.symbol}
        rate={transaction.form.deposit_ld?.proposal?.rate}
        note={transaction.form.deposit_ld?.proposal?.note}
        payment_method={transaction.form.deposit_ld?.proposal?.payment_method}
        payingValue={
          transaction.amount * transaction.form.deposit_ld?.proposal?.rate
        }
        panel="USER"
        method="DEPOSIT"
      />
      <Stack mt="2">{STATES[transaction.status]}</Stack>
    </AccordionPanel>
  );
};
export default TransactionCard;
