import React from 'react';
import { Accordion, AccordionItem, Stack, Text } from '@chakra-ui/react';
import { useTranslation } from 'localization';
import { Proposal } from 'types';

import { LDProposalAccordionButton, Spinner } from 'components';

import ProposalCard from '../ProposalCard';

type ProposalsProps = {
  proposals: Proposal[];
  loading: boolean;
  refetch: () => void;
  refetchTransaction: () => void;
};

const Proposals: React.FC<ProposalsProps> = ({
  proposals,
  loading,
  refetch,
  refetchTransaction,
}) => {
  const { t } = useTranslation('component.ldRequests.proposals');

  const [active, setActive] = React.useState<null | Proposal>(null);
  return (
    <Stack w={{ base: '100%' }}>
      <Text fontSize="xl" color="primary">
        {t('inProcessRequests')}
      </Text>
      {loading && (
        <Stack w="100%" py={4} justify="center" align="center">
          <Spinner />
        </Stack>
      )}
      {proposals.length === 0 && (
        <Stack w="100%" py={4} justify="center" align="center">
          <Text>{t('noProposal')}</Text>
        </Stack>
      )}
      <Accordion
        allowToggle
        w="100%"
        onChange={(active) => {
          if (proposals && active !== null) {
            setActive(
              proposals.filter((proposal) => proposal.status !== 'COMPLETED')[
                active as number
              ]
            );
          }
        }}
      >
        {proposals
          .filter(
            (proposal) => !['FAILED', 'COMPLETED'].includes(proposal.status)
          )
          .map((proposal) => {
            return (
              <Stack key={proposal.id} w="100%">
                <AccordionItem
                  w="100%"
                  bgColor={active?.id === proposal.id ? 'lightPrimary' : ''}
                >
                  <LDProposalAccordionButton
                    createdAt={proposal.created_at}
                    username={
                      proposal?.destination_wallet?.user?.ld_contract
                        ?.nick_name || ''
                    }
                    status={proposal.status}
                    panel="USER"
                    transaction_type="WITHDRAW"
                  />
                  <ProposalCard
                    proposal={proposal}
                    refetch={refetch}
                    refetchTransaction={refetchTransaction}
                  />
                </AccordionItem>
              </Stack>
            );
          })}
      </Accordion>
    </Stack>
  );
};
export default Proposals;
