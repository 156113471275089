import React from 'react';
import { Accordion, AccordionItem, Stack, Text } from '@chakra-ui/react';
import { useTranslation } from 'localization';
import { Transaction } from 'types';

import { LDTransactionAccordionButton, Spinner } from 'components';

import TransactionCard from '../TransactionCard';

type TransactionsProps = {
  transactions: Transaction[];
  loading: boolean;
};

const Transactions: React.FC<TransactionsProps> = ({
  transactions,
  loading,
}) => {
  const { t } = useTranslation('component.ldRequests.transactions');

  const [active, setActive] = React.useState<null | Transaction>(null);
  return (
    <Stack w={{ base: '100%' }}>
      <Text fontSize="xl" color="primary">
        {t('readyToDoneRequests')}
      </Text>
      {loading && (
        <Stack w="100%" py={4} justify="center" align="center">
          <Spinner />
        </Stack>
      )}
      {transactions.length === 0 && (
        <Stack w="100%" py={4} justify="center" align="center">
          <Text>{t('noProposal')}</Text>
        </Stack>
      )}
      <Accordion
        allowToggle
        w="100%"
        onChange={(active) => {
          if (transactions && active !== null) {
            setActive(transactions[active as number]);
          }
        }}
      >
        {transactions.map((transaction) => {
          return (
            <Stack key={transaction.id} w="100%">
              <AccordionItem
                w="100%"
                bgColor={active?.id === transaction.id ? 'lightPrimary' : ''}
              >
                <LDTransactionAccordionButton
                  username={
                    transaction?.destination_wallet?.user?.ld_contract
                      ?.nick_name || ''
                  }
                  createdAt={transaction.created_at}
                  status={transaction.status}
                  panel="USER"
                  transaction_type="WITHDRAW"
                />
                <TransactionCard transaction={transaction} />
              </AccordionItem>
            </Stack>
          );
        })}
      </Accordion>
    </Stack>
  );
};

export default Transactions;
