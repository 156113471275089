import React, { useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  FormLabelProps,
  Input as InputChakra,
  InputGroup,
  InputGroupProps,
  InputLeftAddon,
  InputProps,
  InputRightAddon,
  Select,
  Spinner,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import { ReactComponent as CloudArrow } from 'assets/icons/arrow-drop-down.svg';
import { ReactComponent as InputErrorIcon } from 'assets/icons/input_error.svg';
// import { ReactComponent as InputSuccessIcon } from 'assets/icons/input_success.svg';
// import { ReactComponent as InputWarningIcon } from 'assets/icons/input_warning.svg';

type InputPropTypes = {
  error?: string;
  invalid?: boolean;
  isRequired?: boolean;
  leftValue?: string | React.ReactElement;
  rightValue?: string | React.ReactElement;
  label: string;
  isSelectBox?: boolean;
  isTextArea?: boolean;
  isEmptyAtFirst?: boolean;
  options?: { flag?: any; label: string; value: string; disabled?: boolean }[];
  inputGroupStyle?: InputGroupProps;
  labelStyle?: FormLabelProps;
  isLoading?: boolean;
  withIcon?: boolean;
} & InputProps;

const Input = React.forwardRef<HTMLInputElement, InputPropTypes>(
  (
    {
      invalid,
      isRequired = false,
      leftValue = '',
      rightValue = '',
      label,
      error,
      isLoading = false,
      isSelectBox = false,
      isTextArea = false,
      isEmptyAtFirst = false,
      options = [],
      inputGroupStyle,
      labelStyle,
      withIcon = true,
      ...rest
    },
    forwardedRef
  ) => {
    const ref = React.useRef<HTMLInputElement>(null);
    const [filled, setFilled] = useState<boolean>(false);
    return (
      <FormControl isInvalid={invalid} isRequired={isRequired}>
        <FormLabel
          fontWeight="medium"
          color={error ? 'secondary2' : 'secondary2'}
          {...labelStyle}
        >
          {label}
        </FormLabel>
        <InputGroup ref={ref} mb={4} {...inputGroupStyle}>
          {Boolean(leftValue) && (
            // eslint-disable-next-line react/no-children-prop
            <InputLeftAddon borderColor={error ? 'error' : 'body2'} height="12">
              {leftValue}
            </InputLeftAddon>
          )}
          {Boolean(isLoading) && (
            <Stack
              pos="absolute"
              right={4}
              top="50%"
              transform="translate(0, -50%)"
            >
              <Spinner color="primary" />
            </Stack>
          )}
          {isSelectBox ? (
            <Select
              height="12"
              border="1px solid"
              borderColor={error ? 'error' : 'body2'}
              borderRadius={!leftValue && !rightValue && 'lg'}
              borderRightRadius={Boolean(leftValue) && 'lg'}
              borderLeftRadius={Boolean(rightValue) && 'lg'}
              background={error ? 'lightError' : 'secondary'}
              color={error ? 'secondary' : 'secondary2'}
              fontSize="md"
              _placeholder={{ color: error ? 'secondary' : 'secondary2' }}
              _hover={{
                background: 'rgba(241, 248, 242, 0.1)',
              }}
              _focus={{
                _placeholder: { color: 'transparent' },
                background: error
                  ? 'lightBg'
                  : filled
                    ? 'secondary'
                    : 'rgba(241, 248, 242, 0.10)',
                borderColor: error ? 'error' : filled ? 'body2' : 'primary',
              }}
              icon={withIcon ? <CloudArrow /> : <span />}
              rootProps={{
                css: {
                  '.chakra-select__icon-wrapper': {
                    right: '10px !important',
                  },
                },
              }}
              ref={forwardedRef}
              isDisabled={isLoading}
              {...(rest as any)}
            >
              {isEmptyAtFirst && <option disabled selected></option>}
              {options.map((item) => {
                return (
                  <option
                    key={item.value}
                    disabled={item.disabled || false}
                    value={item.value}
                  >
                    {item.label}
                  </option>
                );
              })}
            </Select>
          ) : isTextArea ? (
            <Textarea
              height="12"
              border="1px solid"
              borderColor={error ? 'error' : 'body2'}
              borderRadius="lg"
              background={error ? 'lightError' : 'secondary'}
              color={error ? 'secondary' : 'secondary2'}
              fontSize="md"
              _placeholder={{ color: error ? 'secondary' : 'secondary2' }}
              _hover={{
                background: 'rgba(241, 248, 242, 0.1)',
              }}
              _focus={{
                _placeholder: { color: 'transparent' },
                background: error
                  ? 'lightBg'
                  : filled
                    ? 'secondary'
                    : 'rgba(241, 248, 242, 0.10)',
                borderColor: error ? 'error' : filled ? 'body2' : 'primary',
              }}
              ref={forwardedRef}
              {...(rest as any)}
            />
          ) : (
            <InputChakra
              height="12"
              border="1px solid"
              borderColor={error ? 'error' : 'body2'}
              borderRadius="lg"
              background={error ? 'lightError' : 'secondary'}
              color={error ? 'secondary' : 'secondary2'}
              fontSize="md"
              onKeyDown={() => setFilled(true)}
              onFocus={() => setFilled(false)}
              _placeholder={{ color: error ? 'secondary' : 'secondary2' }}
              _hover={{
                background: 'rgba(241, 248, 242, 0.1)',
              }}
              _focus={{
                _placeholder: { color: 'transparent' },
                background: error
                  ? 'lightBg'
                  : filled
                    ? 'secondary'
                    : 'rgba(241, 248, 242, 0.10)',
                borderColor: error ? 'error' : filled ? 'body2' : 'primary',
              }}
              ref={forwardedRef}
              {...rest}
            />
          )}
          {Boolean(rightValue) && (
            // eslint-disable-next-line react/no-children-prop
            <InputRightAddon
              borderColor={error ? 'error' : 'body2'}
              height="12"
            >
              {rightValue}
            </InputRightAddon>
          )}
        </InputGroup>
        {error ? (
          <Box
            color="error"
            fontSize="sm"
            textAlign="start"
            display="flex"
            alignItems="center"
            gap={1}
            pos="absolute"
            left={0}
            bottom={-5}
          >
            <InputErrorIcon />
            {error}
          </Box>
        ) : (
          ''
        )}
      </FormControl>
    );
  }
);

Input.displayName = 'Input';
export default Input;
