import { AccordionPanel, Stack } from '@chakra-ui/react';
import { useTranslation } from 'localization';
import { Transaction } from 'types';

import {
  DownloadCard,
  LDTransactionCard,
  LDTransactionKeyValue,
  LDTransactionMessage,
} from 'components';

type TransactionCardProps = {
  transaction: Transaction;
};

const TransactionCard: React.FC<TransactionCardProps> = ({
  transaction,
}: TransactionCardProps) => {
  const { t } = useTranslation('component.ldRequests.transactions');

  const STATES: Record<Transaction['status'], React.ReactElement | string> = {
    INIT: '',
    AUTHORIZED: '',
    APPROVED: (
      <LDTransactionMessage
        title={t('giveCodeToLdWhen')}
        status="info"
        textProps={{ color: 'info' }}
      />
    ),
    UNAUTHORIZED: (
      <Stack>
        <LDTransactionMessage
          title={t('transactionNotCleared')}
          status="error"
          textProps={{ color: 'error' }}
        />
      </Stack>
    ),
    CLEARED: transaction?.form.withdraw_ld.proposal ? (
      transaction?.form.withdraw_ld.proposal.payment_method === 'CASH' ? (
        <Stack flexDir="column">
          <LDTransactionKeyValue
            title={t('address')}
            value={transaction?.form.withdraw_ld.proposal.address || '-'}
          />
          {transaction?.destination_wallet?.user?.ld_contract?.description && (
            <LDTransactionMessage
              title={
                transaction?.destination_wallet?.user?.ld_contract?.description
              }
              status="warning"
              textProps={{ color: 'warning' }}
            />
          )}
          <LDTransactionMessage
            title={t('addressHint')}
            status="info"
            textProps={{ color: 'info' }}
          />
        </Stack>
      ) : transaction?.form.withdraw_ld?.bank_receipt ? (
        <Stack flexDir="column">
          <LDTransactionMessage
            title={t('userPayedWithBankTransfer')}
            status="info"
            textProps={{ color: 'info' }}
          />
          <DownloadCard file={transaction?.form.withdraw_ld?.bank_receipt} />
        </Stack>
      ) : (
        <Stack flexDir="column">
          <LDTransactionMessage
            title={t('waitForLDPaying')}
            status="info"
            textProps={{ color: 'info' }}
          />
        </Stack>
      )
    ) : (
      <Stack flexDir="column">
        <LDTransactionMessage
          title="Cleared"
          status="info"
          textProps={{ color: 'info' }}
        />
      </Stack>
    ),
    NOT_CLEARED: (
      <Stack>
        <LDTransactionMessage
          title={t('transactionNotCleared')}
          status="error"
          textProps={{ color: 'error' }}
        />
      </Stack>
    ),
    CANCELED: (
      <Stack>
        <LDTransactionMessage
          title={t('transactionNotCleared')}
          status="error"
          textProps={{ color: 'error' }}
        />
      </Stack>
    ),
    SETTLED: (
      <Stack>
        {transaction?.form.withdraw_ld.proposal
          ? transaction?.form.withdraw_ld.proposal.payment_method ===
              'BANK_TRANSFER' && (
              <DownloadCard
                file={transaction?.form.withdraw_ld?.bank_receipt || ''}
              />
            )
          : ''}
        <LDTransactionMessage
          title={t('transactionDone')}
          status="success"
          textProps={{ color: 'success' }}
        />
      </Stack>
    ),
    DENIED: (
      <Stack>
        <LDTransactionMessage
          title={t('transactionNotCleared')}
          status="error"
          textProps={{ color: 'error' }}
        />
      </Stack>
    ),
    FAILED: '',
    SETTLING: '',
  };

  return (
    <AccordionPanel pb={4}>
      <LDTransactionCard
        amount={transaction.amount}
        identifier_number={
          transaction.destination_wallet.user.identifier_number
        }
        pay_with={transaction.form.withdraw_ld?.proposal?.pay_with}
        symbol={transaction?.destination_wallet?.currency?.symbol}
        rate={transaction.form.withdraw_ld?.proposal?.rate}
        note={transaction.form.withdraw_ld?.proposal?.note}
        payment_method={transaction.form.withdraw_ld?.proposal?.payment_method}
        receivingValue={
          transaction.amount * transaction.form.withdraw_ld?.proposal?.rate
        }
        panel="USER"
        method="WITHDRAW"
      />
      <Stack mt="2">{STATES[transaction.status]}</Stack>
    </AccordionPanel>
  );
};
export default TransactionCard;
