import { AccordionPanel, Stack } from '@chakra-ui/react';
import { useTranslation } from 'localization';
import Approved from 'pages/Requests/components/Approved';
import RateForm from 'pages/Requests/components/RateForm';
import { Proposal } from 'types';

import { LDTransactionCard, LDTransactionMessage } from 'components';

type ProposalCardProps = {
  proposal: Proposal;
  refetch: () => void;
};

const ProposalCard: React.FC<ProposalCardProps> = ({
  proposal,
  refetch,
}: ProposalCardProps) => {
  const { t } = useTranslation('component.requests.proposals');

  const STATES: Record<Proposal['status'], React.ReactElement | string> = {
    INIT: proposal ? (
      <RateForm proposalId={proposal.id} refetch={refetch} />
    ) : (
      ''
    ),
    PENDING: (
      <LDTransactionMessage
        title={t('waitForUser')}
        status="info"
        textProps={{ color: 'info' }}
      />
    ),
    CANCELED: (
      <LDTransactionMessage
        title={t('canceled')}
        status="error"
        textProps={{ color: 'error' }}
      />
    ),
    APPROVED: proposal ? (
      <Approved
        proposalId={proposal.id}
        paymentMethod={proposal.payment_method}
        refetch={refetch}
      />
    ) : (
      ''
    ),
    REJECTED: (
      <LDTransactionMessage
        title={t('rejected')}
        status="error"
        textProps={{ color: 'error' }}
      />
    ),
    SETTLED: proposal ? (
      <LDTransactionMessage
        title={t('waitForUserPaymentGateway')}
        status="info"
        textProps={{ color: 'info' }}
      />
    ) : (
      ''
    ),
    COMPLETED: '',
    FAILED: '',
  };

  return (
    <AccordionPanel pb={4}>
      <LDTransactionCard
        amount={proposal.amount}
        identifier_number={proposal?.source_wallet?.user?.identifier_number}
        pay_with={proposal.pay_with}
        symbol={proposal?.destination_wallet?.currency?.symbol || ''}
        rate={proposal.rate}
        note={proposal.note}
        payment_method={proposal.payment_method}
        payingValue={proposal.amount * proposal.rate}
        panel="LD"
        method="WITHDRAW"
      />
      <Stack mt="2">{STATES[proposal.status]}</Stack>
    </AccordionPanel>
  );
};
export default ProposalCard;
