import { useForm } from 'react-hook-form';
import { Alert, Box, Text, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import useInputAttributes from 'hooks/useInputAttributes';
import { useTranslation } from 'localization';
import { AccountFields } from 'types';

import { Button, Input } from 'components';
import { yup } from 'utils';

type PayloadType = Record<string, string | number> & { title: string };

type BankAccountFormType = {
  loading: boolean;
  accountFields: AccountFields[];
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: PayloadType) => Promise<void>;
};

const BankAccountForm: React.FC<BankAccountFormType> = ({
  loading,
  onSubmit,
  accountFields,
}: BankAccountFormType) => {
  const { t } = useTranslation('component.deposit.initModal');

  const dynamicAttributes = accountFields.reduce(
    (obj, item) => ({ ...obj, [item.name]: item.title }),
    { title: t('title') }
  );

  const dynamicValidator = {
    string: { 0: yup.string(), 1: yup.string().required() },
    number: { 0: yup.number(), 1: yup.number().required() },
  };

  const dynamicYup = accountFields.reduce(
    (obj, item) => ({
      ...obj,
      [item.name]: dynamicValidator[item.type][item.required ? 1 : 0].label(
        item.title
      ),
    }),
    { title: yup.string().label(t('title')).required() }
  );

  const schema = yup.object().shape(dynamicYup);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const formProps = useInputAttributes(register, errors, dynamicAttributes);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack w="100%" align="flex-start" p={8}>
        <Box w="100%">
          <Input {...formProps.title} />
        </Box>
        {accountFields.map((filed) => {
          return (
            <Box w="100%" key={filed.name}>
              <Input {...formProps[filed.name]} />
              {filed.name === 'IBAN' && (
                <Alert colorScheme="yellow">
                  <Text color="warning" textAlign="center" w="100%">
                    {t('hintIBAN')}
                  </Text>
                </Alert>
              )}
            </Box>
          );
        })}
        <Button
          type="submit"
          variant="filled"
          display="block"
          isLoading={loading}
          title={t('proceed')}
          minW="100%"
          mt={{ base: 8, md: 8 }}
        />
      </VStack>
    </form>
  );
};
export default BankAccountForm;
