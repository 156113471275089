/* eslint-disable no-unused-vars */
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Alert, Box, HStack, Stack, Text, VStack } from '@chakra-ui/react';
import { ReactComponent as XMLogo } from 'assets/icons/xm.svg';
import { useServiceTransaction, useTimer, useWallet } from 'hooks';
import { useTranslation } from 'localization';
import { routes } from 'statics';

import { Button, Spinner } from 'components';
import { fixDecimal } from 'utils';

import Form from '../Form';

type TrRowProps = { title: string; value: any };
const TrRow: React.FC<TrRowProps> = ({ title, value }: TrRowProps) => {
  return (
    <HStack w="full" justify="space-between" py={2}>
      <Text fontSize={{ base: 'lg', md: 'xl' }}>{title + ' : '}</Text>
      <Text fontSize="xl" color="body2">
        {value}
      </Text>
    </HStack>
  );
};

type DepositProcessProps = {
  onAction: ({
    status,
    redirectUrl,
  }: {
    status: 'success' | 'cancel';
    redirectUrl: string;
  }) => void;
};

const DepositProcess: React.FC<DepositProcessProps> = ({ onAction }) => {
  const { t } = useTranslation('component.services.xm.deposit');

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const transactionId = searchParams.get('transaction_id') || '';

  React.useEffect(() => {
    if (!transactionId) {
      navigate(routes.dashboard.url);
    }
  }, []);

  const {
    data: transaction,
    isLoading,
    isError,
  } = useServiceTransaction({
    id: transactionId,
  });

  const { data: walletDetail, isLoading: walletLoading } = useWallet({
    id: transaction?.source_wallet?.id || '',
  });

  // eslint-disable-next-line max-len
  const depositUrl = `${routes.deposit.url}?tab=0&wallet=${walletDetail?.id}&currency=${walletDetail?.currency.id}`;
  const sufficientBalance =
    transaction &&
    walletDetail &&
    walletDetail?.amount - walletDetail?.frozen_amount >= transaction?.amount;

  const { counter, setCounter } = useTimer(0);

  React.useEffect(() => {
    if (transaction && walletDetail && !sufficientBalance) {
      setCounter(5);
    }
  }, [walletDetail, transaction]);

  React.useEffect(() => {
    if (transaction && walletDetail && !sufficientBalance && counter === 0) {
      navigate(depositUrl);
    }
  }, [counter]);

  React.useEffect(() => {
    if (isError) {
      navigate(routes.dashboard.url);
    }
  }, [isError]);

  return (
    <Stack
      bgColor="dark"
      px={4}
      py={2}
      w={{ base: 'full', lg: '600px' }}
      align="center"
    >
      <XMLogo width={120} />
      <Text fontSize="xl" fontWeight="500">
        {t('title')}
      </Text>
      {isLoading && (
        <Box p={8}>
          <Spinner />
        </Box>
      )}
      {transaction?.id && (
        <Box w="full">
          <VStack w="full">
            <TrRow
              title={t('trData.currentBalance')}
              value={
                walletLoading && !walletDetail ? (
                  <Spinner size="sm" />
                ) : (
                  fixDecimal({
                    value: walletDetail?.amount || 0,
                    fractionDigits: 2,
                  }) + ' $'
                )
              }
            />
            <TrRow
              title={t('trData.amount')}
              value={transaction?.amount + ' $'}
            />
          </VStack>
          {walletLoading ? (
            <Stack w="100%" align="center" justify="center" p={8}>
              <Spinner />
            </Stack>
          ) : sufficientBalance ? (
            <Form onAction={onAction} transactionId={transaction?.id || ''} />
          ) : (
            <Alert status="warning" mt={10}>
              <Stack w="100%" align="center" justify="center" flexDir="column">
                <Text w="100%" textAlign="center" color="warning">
                  {t('notEnoughBalance')}
                </Text>
                <Button
                  size="sm"
                  minW={40}
                  title={t('redirectToDeposit') + ' ' + counter.toString()}
                  onClick={() => navigate(depositUrl)}
                />
              </Stack>
            </Alert>
          )}
        </Box>
      )}
    </Stack>
  );
};
export default DepositProcess;
